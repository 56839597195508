@import "../../scss/breakpoints";
@import "../../scss/variables";

.mobileNavigationContainer {
  background-color: $primary-color;
  position: absolute;
  top: $mobile-nav-bar-height;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  width: 50%;
  transition: all 0.2s ease-in-out;
  visibility: hidden;
  opacity: 0;

  &.open {
    visibility: visible;
    opacity: 100;
    z-index: 999;
  }

  div:last-of-type button {
    border-bottom: 0;
  }
}

.navigationContainer {
  display: none;
  position: absolute;
  z-index: 9999;
  text-align: center;
  background-color: $primary-color;
  height: 100%;
  width: 50px;
  flex-direction: column;
  justify-content: center;

  @include screen-sm {
    display: flex;
  }
}

.navigationSlider {
  > div {
    display: flex;
    flex-direction: column;
    border-top: 1px solid $primary-color;

    &:last-child {
      border-bottom: 1px solid $primary-color;
    }

    &.selected button {
      background-color: white;
      color: $primary-color;
    }

    &:last-of-type {
      margin-right: 0;
    }
  }
}
