@import "./variables";

.root {
  width: 100%;
  height: 100vh;
}

body {
  font-family: "Open Sans", sans-serif;
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  -webkit-font-smoothing: antialiased;
  overflow: hidden;
  background-color: $primary-color;

  &[data-resume-only] {
    background-color: #fff;
    overflow-y: scroll;
  }

  @media print {
    color: #fff;
    background-color: #fff;
  }
}

::placeholder {
  color: $primary-color;
  opacity: 1;
}

a,
button {
  &:focus {
    outline: none;
  }
}
