@import "../../scss/breakpoints";
@import "../../scss/variables";

/* stylelint-disable scss/no-global-function-names */
.tabWrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;

  &.selected button {
    background-color: white;
    color: $primary-color;
  }

  &:not(:last-child) {
    @include screen-sm {
      &::after {
        content: "";
        background: darken($primary-color, 5%);
        width: 100%;
        height: 1px;
      }
    }
  }

  .buttonContainer {
    display: flex;
    flex-direction: row;
    min-width: 50px;
    gap: 5px;

    button {
      min-width: 50px;
    }
  }
}
/* stylelint-enable scss/no-global-function-names */

.tab {
  cursor: pointer;
  color: white;
  font-size: 17px;
  line-height: 46px;
  padding: 0;
  border: 0;
  height: 46px;
  width: 50px;
  background-color: transparent;
  transition: all 0.3s ease-in-out;

  & ~ span {
    display: none;
    visibility: hidden;
    opacity: 0;
    background-color: $primary-color;
    color: white;
    font-size: 12px;
    font-weight: 600;
    min-width: 90px;
    padding: 14px 0;
    text-transform: uppercase;
    transition: opacity 0.2s 0s, visibility 0s 0.2s, transform 0.2s 0s;
    white-space: nowrap;

    @include screen-sm {
      display: inline;
    }
  }

  &:hover {
    background-color: white;
    color: $primary-color;
    transition: all 0.3s ease-in-out;

    & ~ span {
      opacity: 1;
      visibility: visible;
      transition: opacity 0.2s 0s, visibility 0s 0s, transform 0.2s 0s;
    }
  }
}

.tabWrapper.horizontal {
  &:not(:last-child) {
    @include screen-sm {
      &::after {
        width: 1px;
        height: 100%;
      }
    }
  }

  .buttonContainer {
    flex-direction: column-reverse;
    max-width: 50px;

    span {
      display: flex;
      flex-direction: column;
      justify-content: center;
      min-width: 0;
      writing-mode: vertical-lr;
      text-orientation: upright;
    }
  }
}
