@import "../../../scss/variables";
@import "../../../scss/breakpoints";

.content {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.contact {
  align-self: center;
}

.introduction {
  flex-direction: column;
  margin-bottom: 1rem;

  h1 {
    font-family: Montserrat, sans-serif;
    font-size: 2rem;
    text-align: center;
    font-weight: 300;
  }

  .name {
    font-size: 2.6rem;
    font-weight: 800;
    text-align: center;
  }
}

.picture {
  border-radius: 50%;
  object-fit: cover;
  margin-left: auto;
  margin-right: auto;
  box-shadow: 0 0 10px 0;
  margin-bottom: 1rem;
}

.wordsCarousel {
  text-align: center;
  margin-bottom: 1rem;

  & > div {
    display: inline-block;
    min-width: 180px;
    min-height: 20px;
    text-align: left;
    margin-left: 10px;
    pointer-events: none;
  }
}
