@import "../../scss/breakpoints";
@import "../../scss/variables";

.wrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
  position: absolute;
  bottom: 0;
  height: 50px;
  width: 100%;
  background-color: $primary-color;

  @include screen-sm {
    bottom: 50px;
  }
}

.container {
  display: flex;
  justify-content: center;
  max-height: 50px;
  position: absolute;
  background-color: $primary-color;
}
