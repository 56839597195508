@import "../../scss/variables";
@import "../../scss/breakpoints";

.container {
  flex: 1 1 0;
  display: flex;
  flex-direction: column;
  justify-content: center;

  @include screen-lg {
    max-width: 300px;
  }

  a {
    display: inline-block;
    padding: 10px 0;
    border: 2px solid $primary-color;
    color: $primary-color;
    text-decoration: none;
    text-align: center;
  }
}
