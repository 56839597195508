@import "scss/variables";
@import "scss/breakpoints";

.contentWrapper {
  padding: 15px 15px 0;
  height: calc(100vh - 115px);
  background-color: $primary-color;
  color: $primary-color;

  @include screen-sm {
    padding: 50px 50px 100px 0;
    margin-left: 50px;
  }

  .container {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    background-color: white;
    justify-content: center;
  }

  .content {
    width: 85%;
    height: 85%;
    margin-left: auto;
    margin-right: auto;
    overflow-y: auto;
  }
}
