@import "../../scss/variables";

.btn {
  min-height: 2.5rem;
  background: transparent;
  border-color: $primary-color;
  color: $primary-color;

  &:hover,
  &:focus {
    background-color: $primary-color;
    color: #fff;
    border-color: #fff;
  }
}
