@import "../../../scss/breakpoints";
@import "../../../scss/variables";

.wrapper {
  height: 100%;
}

.container {
  display: flex;
  flex-direction: column;
  flex-basis: 50%;
  margin: 0 2%;
}

.aboutMeWrapper {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  justify-content: space-evenly;

  & > div {
    flex: 1 1 0;
    min-width: 0;
  }

  @include screen-lg {
    flex-direction: row;
  }
}

.aboutMeProfessional {
  display: flex;
  justify-content: center;
  gap: 1.5rem;
  flex-direction: column;

  @include screen-sm {
    flex-direction: row;
  }

  & > div {
    &:nth-child(1) {
      order: -1;
    }

    flex: 1 1 0;
    display: flex;
    flex-direction: column;
    justify-content: center;

    @include screen-lg {
      max-width: 300px;
    }

    iframe {
      width: 100%;
    }
  }
}

.aboutMeSubSection {
  margin: 3rem 0;
}

.separator {
  // display: flex;
  &::before {
    content: "OR";
    display: flex;
    align-items: center;
    justify-content: center;
    align-self: stretch;
    font-weight: 700;
  }
}

.aboutMeHobbiesSection {
  display: flex;
  gap: 2rem;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 1rem;

  .hobby {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    flex: 1 1 25%;
    align-items: center;
  }
}

.carouselImages {
  img {
    // Height comes from src/js/app.module.scss .contentWrapper and react-image-gallery
    // 100vh - .contentWrapper height - padding - buttons from lib class (71px each)
    max-height: calc(100vh - 115px - 100px - 140px) !important;
  }
}
